<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400">{{
        $t("non_im.department")
      }}</span>
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row>
        <v-col cols="4"
          ><v-text-field label="search" solo dense></v-text-field
        ></v-col>
        <v-spacer />
        <v-col cols="4" class="text-right">
          <v-btn dark color="#de5c8e" @click="opendialog()"
            >เพิ่ม{{ $t("non_im.department") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-col cols="12">
        <v-data-table v-if="EngLang" :headers="headersEN" :items="items">
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name_of_user }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
        <!-- แยกภาษา -->
        <v-data-table v-if="!EngLang" :headers="headersTH" :items="items">
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
            <v-icon @click="DeleteItem(item.id)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
    <Dialog
      :dialog="dialog"
      :isEdit="isEdit"
      :form="form"
      :affiliation_faculty="affiliation_faculty"
      :EngLang="EngLang"
      @closeDialog="closeDialog()"
    />
  </v-row>
</template>

<script>
import { Decode } from "@/services";
import moment from "moment";
import Dialog from "@/components/Dialog/DialogDepartment";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      form: {
        name_th: "",
        name_en: "",
        mAffiliationFacultyId: null,
      },
      userData: [],
      count: 0,
      EngLang: true,
      nationalityItems: [],
      headersTH: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อไทย", value: "name_th", align: "center" },
        { text: "ชื่ออังกฤษ", value: "name_en", align: "center" },
        { text: "วันที่เพิ่ม", value: "createdAt", align: "center" },
        // { text: "สถานะ", value: "status",width:300 },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersEN: [
        { text: "No.", value: "count", align: "center" },
        { text: "Name Thai", value: "name_th", align: "center" },
        { text: "Name Eng", value: "name_en", align: "center" },
        { text: "Created At", value: "createdAt", align: "center" },
        // { text: "Status", value: "status",width:300 },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      affiliation_faculty: [],
    };
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }
    this.getallaffiliation_faculty();
    this.getAll();
  },
  methods: {
    async getallaffiliation_faculty() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/affiliation_faculty`
      );
      console.log("response", response.data.data);
      this.affiliation_faculty = response.data.data;
    },
    async getAll() {
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      // const data = {
      //   ...this.form,
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/department`
      );
      console.log("response", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    opendialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.isEdit = false;
      this.form = {};
      this.getAll();
    },
    convertDate(val) {
      return moment(val).format("DD/MM/yyyy");
    },
    goToEdit(item) {
      console.log("item", item);
      this.form = item;
      this.isEdit = true;
      this.dialog = true;
      // this.$router.push(`/Edit-Doc1?id=${id}`);
    },
    async DeleteItem(id) {
      this.$swal({
        text: this.EngLang
          ? "Do you want to delete ?"
          : "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/department/${id}`,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAll();
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>